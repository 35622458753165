<template>
  <div v-editable="blok" :class="$style['rich-text']">
    <Vue3RuntimeTemplate :template="resolvedRichText" />
  </div>
</template>
<script setup>
import Vue3RuntimeTemplate from 'vue3-runtime-template'
const props = defineProps({ blok: Object })
const resolvedRichText = computed(() => renderRichText(props.blok.text, {
  resolver: (component, blok) => {
    return `<component :blok='${JSON.stringify(blok)}' is="${component}" />`
  }
}))
</script>
<style module>
.rich-text {
  :is(ol, ul) {
    display: inline-flex;
    flex-direction: column;
    font-size: 1.1em;
    line-height: 1.5em;
    padding-left: 1.5em;
    margin-bottom: 1.5em;

    /* list-style: none; */
  }

  /* li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-l);
    background-color: var(--primary-color);
    color: var(--background-base);
    font-weight: var(--font-weight-bold);
    font-family: var(--font-family-heading);
    margin: var(--spacing) 0 ;
    padding: var(--spacing) var(--spacing-l);
    text-shadow: 1px 1px 0 #fff;
    text-align: center;
    flex-grow: 0;
  } */

  li p {
    margin: 0;
  }
}
</style>
